.publication_area {
  .publication_item {
    background: $white;
    transition: $transition;
    position: relative;
    z-index: 1;
    height: 100%;
    border: 1px solid transparent;
    overflow: hidden;

    .pub-img {
      img {
        transition: $transition;
      }
    }

    .content {
      visibility: hidden;
      opacity:0;
      padding: 14px;
      background: $white;
      left: 0;
      bottom: 20px;
      transition: $transition;

      h3 {
        font-size: 22px;
        font-weight: 600;
        color: $primary;
        margin-bottom: 8px;
        @media (max-width:767.98px) {
          font-size: 18px;
        }
      }

      p {
        font-size: 14px;
        font-weight: 400;

        strong {
          font-weight: 700;
        }
      }

    }

    &:hover {
      border-color: $black;

      .content {
        bottom: 0;
        visibility: visible;
        opacity: 1;
      }

      .pub-img {
        img {
          transform: scale(1.05);
        }
      }
    }
  }
}