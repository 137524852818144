.publication_area {
    .publication_item {
        .img {
            height: 287px;

            @media (max-width:1399.98px) {
                height: 243px;
            }
            @media (max-width:1199.98px) {
                height: 277px;
            }
            @media (max-width:991.98px) {
                height: 243px;
            }
            @media (max-width:767.98px) {
                height: 319px;
            }
            @media (max-width:575.98px) {
                height: unset;
            }

            img {
                object-fit: cover;
                height: 100%;
                object-fit: cover;
            }
        }

        .text {
            background: $black;
            padding: 5px 10px;
            border-top: 3px solid $primary;

            .title {
                color: $white;
                font-size: 18px;
                font-weight: 500;
            }
        }
    }
}
