.page_header {
  color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .overlay {
    background: rgba(0, 0, 0, 0.6);
    padding: 20px 0;
  }

  .page_title {
    font-size: 20px;
    color: #ffffff;
    line-height: 1.2em;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0;


    @media (max-width:767.98px) {
      font-size: 22px;
    }
  }

  .page_nav {
    text-align: left;

    li {
      font-size: 14px;
      font-weight: 500;

      @media (max-width:767.98px) {
        font-size: 14px;
      }

      a {
        color: $white;

      }
      i {
        font-size: 12px;
        padding: 0 8px 0 6px;
      }

      &:last-child {
        i {
          display: none;
        }
      }
    }
  }
}