.section-sub-title {
    &.title {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 30px;
    }
}
.staff-directory {
    .staff-infos {
        background-color: $white;
        box-shadow: 0px 5px 60px 0px rgba(0, 0, 0, 0.05);
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 12px;
        transition: $transition;

        &:hover {
            border-color: $black;
        }

        ul {
            // border-left: 1px solid #ccc;
            padding-left: 14px;
        }

        li {
            margin-bottom: 6px;
            // position: relative;
            i{
                margin-right: 16px;
            }


            strong {
                margin-right: 10px;
            }

            // &::before {
            //     position: absolute;
            //     top: 50%;
            //     left: -19px;
            //     transform: translateY(-50%);
            //     width: 9px;
            //     ;
            //     height: 9px;
            //     border-radius: 50%;
            //     background-color: $black;
            //     content: "";
            //     transition: $transition;
            // }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &:hover {
            li {
                // &::before {
                //     background: $primary;
                // }

                i{
                    color: $primary;
                }
            }
        }
    }
}
