.news_ticker_area {
    margin-top: 5px;

    .new_bg {
        background: #E8E8E8;
    }

    .title {
        background: $black;
        padding: 9px 40px;
        font-size: 20px;
        color: $white;
        font-weight: 600;
        
    }

    // p {
    //   font-size: 20px;
    //   font-weight: 500;
    //   color: $black;
    //   
    //   padding: 0 20px;
    // }

}

.marquee {
    width: 100%;
    overflow: hidden;
    position: relative;

    ul {
        visibility: hidden;
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        white-space: nowrap;
        display: inline;

        li {
            display: inline-block;

            a {
                font-size: 20px;
                font-weight: 500;
                color: $black;
                
                padding: 0 10px;

                &:hover{
                    background-color: $primary;
                    border-radius: 3px;
                    color: $white;
                }
            }
        }
    }
}
