.new_partners {

    .partner_label {
        &:nth-child(odd) {
            .partner_item {
                border-top-right-radius: 70px;
                border-bottom-left-radius: 70px;
                border-bottom-right-radius: 10px;
                border-top-left-radius: 10px;
            }
        }

        &:nth-child(even) {
            .partner_item {
                border-top-right-radius: 10px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 70px;
                border-top-left-radius: 70px;
            }
        }

        &:hover {
            &:nth-child(odd) {
                .partner_item {
                    border-top-right-radius: 10px;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 70px;
                    border-top-left-radius: 70px;
                }
            }

            &:nth-child(even) {
                .partner_item {
                    border-top-right-radius: 70px;
                    border-bottom-left-radius: 70px;
                    border-bottom-right-radius: 10px;
                    border-top-left-radius: 10px;
                }
            }
        }
    }

    .partner_item {
        height: 300px;
        background: $white;
        box-shadow: 0px 5px 60px 0px rgba(0, 0, 0, 0.05);
        padding: 20px 30px;
        height: 100%;
        border: 2px solid transparent;
        transition: $transition;

        .img {
            max-width: 200px;
            margin: 0 auto;
            height: 160px;
            overflow: hidden;

            img {
                transition: $transition;
                width: 100%;
                object-fit: contain;
            }
        }

        .text {
            margin-top: 20px;
            text-align: center;

            .title {
                font-size: 18px;
                font-weight: 600;
                color: $black;
                line-height: 26px;
            }
        }

        &:hover {
            border-color: $black;
        }
    }
}
