@import "../variable/variable";
@import "../mixin/font";

.banner_section {

  .banner-slider {

    .arrow {
      width: 56px;
      height: 56px;
      background: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 9;
      color: #656361;
      cursor: pointer;
      transition: $transition;
      border-radius: 5px;

      @media (max-width:991.98px) {
        width: 42px;
        height: 42px;
      }

      @media (max-width:575.98px) {
        width: 30px;
        height: 30px;
      }

      &:hover {
        background: $primary;
        color: $white;
      }
    }

    .arrow-prev {
      left: 0;
    }

    .arrow-next {
      right: 0;
    }
  }
}

.banner_sm_main {
  left: 0;
  bottom: 20px;
  .banner_sm_slider{
    max-width: 450px;
    margin: 0 auto;
    .slick-slide{
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width:575.98px) {
    bottom: 10px;
  }

  // .slick-list {
  //   padding: 10px 0 !important;
  //   margin: -10px 0;
  // }

  .slider_sm_item {

    .img {
      border-radius: 50%;
      border: 1px solid $black;
      // margin: 0 10px;
      cursor: pointer;
      width: 48px;
      height: 48px;

      @media (max-width:575.98px) {
        width: 36px;
        height: 36px;
        margin: 0 5px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      transition: $transition;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
}