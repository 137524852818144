.member_page_area {
    padding-top: 80px;
}

.single-team-card {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 14px;
    transition: $transition;
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.team-page-card {
        background: #FFFFFF;
        box-shadow: 0px 0px 30px rgba(85, 85, 85, 0.1);
        border-radius: 20px;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 0;
        background-color: $black;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
        border-radius: 20px;
    }

    .team-img {
        transition: $transition;
        overflow: hidden;
        border-radius: 20px;
        height: 350px;

        @media (max-width:1399.98px) {
            height: 304px;
        }

        @media (max-width:1199.98px) {
            height: 348px;
        }

        @media (max-width:991.98px) {
            height: 397px;
        }

        @media (max-width:767.98px) {
          height: 283px;
        }

        @media (max-width:575.98px) {
            height: unset;
        }

        img {
            border-radius: 20px;
            transition: $transition;
            height: 100%;
            object-fit: cover;
        }
    }

    .single-team-content {
        text-align: center;
        padding-top: 26px;
        padding-bottom: 10px;

        h3 {
            font-size: 18px;
            font-weight: 600;
            line-height: 140%;
            margin-bottom: 15px;
            transition: $transition;

            @media (max-width:767.98px) {
                font-size: 15px;
            }
        }

        p {
            transition: $transition;
            font-size: 15px;
            font-weight: 600;
            color: $primary;
            text-transform: uppercase;
        }

    }

    .tem_btn {
        margin-top: auto;
        text-align: center;
    }

    .view_more {
        border-radius: 50px;
        font-size: 15px;
        font-weight: 500;
        color: $black;
        margin-top: 20px;
        padding: 8px 20px;
        border: 1px solid $black;

        &:hover {
            background-color: $primary;
            color: $black;
            border-color: $primary !important;
        }
    }

    &:hover {
        .team-img {
            img {
                transform: scale(1.2);
            }
        }

        .single-team-content {
            h3 {
                color: $white;
            }

            p {
                color: $white;
            }
        }

        .view_more {
            color: $white;
            border-color: $white;
        }

        &::after {
            height: 100%;
            opacity: 1;
            visibility: visible;
        }
    }
}

.pagination {
    margin-top: 50px;

    ul {
        gap: 15px;
        flex-wrap: wrap;

        li {
            box-shadow: none !important;

            .paginate {
                width: 40px;
                height: 40px;
                border: 0;
                border: 1px solid #dddddd;
                font-weight: 700;
                color: $black;
                font-size: 15px;
                line-height: 36px;

                &:hover {
                    border-color: transparent;
                    background-color: $primary;
                    color: $white;
                }
            }

            .active {
                border-color: transparent;
                background-color: $primary;
                color: $white;
            }
        }
    }
}
