.research_area {
    .research_item {
        overflow: hidden;
        img {
            transition: $transition;
        }
        .text {
            background: rgba(40, 43, 102, 0.4);
            padding: 10px 20px;
            .title {
                font-size: 20px;
                font-weight: 500;
                color: $white;
            }
        }
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }
}

.faq-body {
    .accordion-button {
        padding: 20px 30px;
        &:focus {
            -webkit-box-shadow: none;
            box-shadow: none;
        }
        background: transparent;
        border: 1px solid transparent;
        border-radius: 0 !important;
        position: relative;
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
        text-transform: capitalize;
        &::after {
            display: none;
        }
        .arrow-icon {
            background: transparent;
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            background-color: $primary;
            width: 60px;
            height: 60px;
            -webkit-transition: ll 0.3s ease-out 0s;;
            transition: ll 0.3s ease-out 0s;;
            svg {
                width: 25px;
                height: 25px;
                stroke: #fff;
            }
            .minus {
                display: none;
            }
        }
        &:not(.collapsed) {
            background: transparent !important;
            -webkit-box-shadow: none;
            box-shadow: none;
            .arrow-icon {
                background: transparent;
                svg {
                    stroke: $primary;
                }
                .minus {
                    display: block;
                }
                .plus {
                    display: none;
                }
            }
        }
    }
    .accordion-item {
        border: 0;
        background-color: #ECF0F1;
        margin-bottom: 20px;
    }
    .accordion-body {
        padding: 20px 60px 30px 20px;
        border-top: 1px solid #D8DDE1;
    }
}
