.news_area {
    .news_item {
        margin: 0 8px;

        .img {
            position: relative;
            overflow: hidden;
            height: 242px;

            img {
                height: 100%;
                object-fit: cover;
            }

            @media (max-width:1399.98px) {
                height: 208px;
            }

            @media (max-width:1199.98px) {
                height: 235px;
            }

            @media (max-width:991.98px) {
                height: 173px;
            }

            @media (max-width:767.98px) {
                height: 196px;
            }

            @media (max-width:575.98px) {
                height: unset;
            }

            img {
                transition: $transition;
            }

            .date {
                position: absolute;
                bottom: 5px;
                left: 0;
                color: $white;

                background: $primary;
                padding: 2px 5px;
                padding-right: 30px;
            }
        }

        .text {
            padding-top: 5px;

            .title {
                font-size: 22px;

                font-weight: 600;
                color: $black;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                min-height: 52px;

                @media (max-width:991.98px) {
                    font-size: 18px;
                }
            }

            p {

                font-size: 12px;
                margin-top: 5px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                span {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }

            .read_more {
                .btn_link {
                    font-size: 16px;
                    font-weight: 500;
                    color: $black;

                    @media (max-width:991.98px) {
                        font-size: 13px;
                    }
                }
            }
        }

        &:hover {
            .img {
                img {
                    transform: scale(1.05);
                }
            }
        }
    }

    .arrow {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        background: #F0F2F6;

        &:hover {
            background: $primary;
            color: $white;
        }
    }
}


.career {
    table {
        box-shadow: 0px 5px 60px 0px rgba(0, 0, 0, 0.05);

        th,
        td {
            vertical-align: middle !important;
        }

        td {

            center,
            a {
                line-height: 19px;
            }
        }

    }

    .table>:not(caption)>*>* {
        padding: .4rem .5rem;
    }
}



.research-area {
    .single-research {
        .field-title {
            font-size: 18px;
            padding: 15px 12px;
            border-radius: 6px;
            background: $black;
            border: 0;
            transition: $transition;

            a {
                color: $white;
            }

            &:hover {
                background: $primary;
            }
        }
    }
}


.publication-details {

    .details-main {

        .text {
            margin-left: 24px;

            a {
                font-size: 32px;
                font-weight: 600;
                color: $black;

            }

            .sub {
                padding: 10px 20px;
                background-color: $primary;
                margin-top: 10px;
            }

            h6 {
                font-size: 20px;
                font-weight: 500;
                color: $white;
                padding: 0;
                padding-bottom: 0;
            }

        }
    }
}
