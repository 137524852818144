@import "../variable/variable";
@import "../mixin/font";
@import "../mixin/line-limit";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
}
html{
    margin-right: 0 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    font-family: $outfit !important;
}

.table-header{
    background: $primary;
    color: $white;
}

p {
    margin: 0;
    padding: 0;
    font-family: $poppins !important;
}

span {
    display: inline-block;
}

a,
button {
    display: inline-block;
    text-decoration: none;
}

a:hover,
a:focus {
    text-decoration: none;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

a,
button {
    transition: all 0.3s ease-out 0s;
}

body {
    overflow-x: hidden;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    font-family: $poppins !important;
    line-height: 160%;
    overflow-y: scroll;
}

::selection {
    background-color: $primary;
    color: $white;
}

p {
    color: $black;
    line-height: 150%;
    color: #3F3F3F;
}

.mt-80 {
    margin-top: 80px;
}

.mb-n80 {
    margin-bottom: -80px;
}

.mt-40 {
    margin-top: 40px;
}

.py-80 {
    padding: 80px 0;
}


/* Loader css */
.fullpage_loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
        width: 3.125rem;
        aspect-ratio: 1;
        display: grid;
    }

    .loader::before,
    .loader::after {
        content: "";
        grid-area: 1/1;
        --c: no-repeat radial-gradient(farthest-side, #03256D 92%, transparent);
        background:
            var(--c) 50% 0,
            var(--c) 50% 100%,
            var(--c) 100% 50%,
            var(--c) 0 50%;
        background-size: 0.75rem 0.75rem;
        animation: l12 1s infinite;
    }

    .loader::before {
        margin: 0.25rem;
        filter: hue-rotate(45deg);
        background-size: 0.5rem 0.5rem;
        animation-timing-function: linear
    }

    @keyframes l12 {
        100% {
            transform: rotate(.5turn)
        }
    }
}


.pt80 {
    padding-top: 80px;
}
// Theme btn
.theme_btn {
    color: $white;
    font-size: 14px;
    font-weight: 400;
    background: $black;
    border-radius: 10px;
    padding: 4px 25px 4px 15px;
    margin-right: 15px;
  
    .arrow {
      width: 36px;
      height: 36px;
      background: $primary;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -3px;
      right: -15px;
    }
  }

.theme-btn {
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    background-color: $white;
    padding: 12px 30px 12px 30px !important;
    border-radius: 60px;
    z-index: 9;
    gap: 6px;
    border: 0;
    color: $black !important;
    border: 1px solid $black !important;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        width: 100%;
        height: 100%;
        background-color: $black !important;
        border-radius: 60px;
        transition: $transition;
        z-index: -1;
        opacity: 0;
    }

    .arrows {
        margin-left: 10px;
        transition: $transition;
        position: relative;
        top: -1px;
    }

    &:hover {
        color: $white !important;

        &::before {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
        }
    }
}

/* Section title css */
.section_title {
    margin-bottom: 24px;

    .title {
        font-size: 28px;
        font-weight: 700;
    }
}

.btn-custom-primary{
    background: $primary !important;
    color: $white !important;
    &:hover{
        background:$black !important; 
        color: $white !important
    }
    &:focus{
        background:$black !important; 
        color: $white !important
    }
}
.form-check-input{
    &:focus{
        box-shadow: none !important
    }
}
// line limit start
.line-limit-1 {
    @include line_limit (1 !important);
}

.line-limit-2 {
    @include line_limit (2 !important);
}

.line-limit-3 {
    @include line_limit (3 !important);
}

.line-limit-4 {
    @include line_limit (4 !important);
}

.line-limit-5 {
    @include line_limit (5 !important);
}

.line-limit-6 {
    @include line_limit (6 !important);
}

.line-limit-7 {
    @include line_limit (7 !important);
}

.line-limit-8 {
    @include line_limit (8 !important);
}

.line-limit-9 {
    @include line_limit (9 !important);
}

.line-limit-10 {
    @include line_limit (10 !important);
}
