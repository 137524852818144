.body_right_sidebar {
  .item_box {
    .title {
      background: $black;
      color: $white;
      font-family: $poppins;
      font-size: 23px;
      font-weight: 600;
      padding: 5px 8px;
      margin-bottom: 10px;
    }
    .img{
      max-width: 200px;
      overflow: hidden;
      img{
        transition: $transition;
        height: 100%;
        object-fit: cover;
      }
    }
    .text{
      margin-top: 5px;
      .name{
        font-size: 18px;
        font-weight: 500;
        color: $black;
      }
      p{
        font-size: 12px;
        font-weight: 500;
        color: $black;
      }
    }
    .file{
      max-width: 133px;
      margin-top: 20px;
      img{
        transition: $transition;
      }
    }
    &:hover{
      .img,.file{
        img{
          transform: scale(1.05);
        }
      }
    }
  }
  .item_box+.item_box{
    margin-top: 24px;
  }
}
