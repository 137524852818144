@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.cdnfonts.com/css/times-new-roman');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "./common/common";
@import "./variable/variable";
@import "./pages/home";
@import "./pages/about";
@import "./pages/member";
@import "./pages/content";
@import "./pages/faq";
@import "./pages/publication";
@import "./pages/contact";
@import "./pages/login";
@import "./pages/news";
@import "./pages/partner";
@import "./pages/new-publication";
@import "./pages/inner-content";
@import "./pages/new-gallery";