.recent_activities_area {

    .nav-link {
        border-radius: 5px;
        background: transparent;
        border: 1px solid #cccccc;
        color: $black;
        margin-right: 10px;
        padding: 8px 30px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;

        &:hover {
            background: $black;
            color: $white;
            border-color: $black;
        }

        &.active {
            background: $black;
            color: $white;
        }
    }

    .article_item_big {
        border: 1px solid #cccccc;
        height: 100%;

        .image {
            overflow: hidden;

            img {
                transition: $transition;
            }
        }

        .text {
            padding: 5px 10px 5px;

            .title {
                font-size: 20px;
                font-weight: 600;

                color: $black;
            }

            p {

                margin-top: 4px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                text-align: justify;
            }
        }

        &:hover {
            .image {
                img {
                    transform: scale(1.05);
                }
            }
        }
    }

    .article_item {
        width: 100%;
        margin-bottom: 18px;
        border: 1px solid #cccccc;

        &:last-child {
            margin-bottom: 0;
        }

        .image {
            width: 140px;
            flex: 0 0 auto;
            height: 140px;
            overflow: hidden;

            img {
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: all linear .2s;
                transition: $transition;
            }
        }

        .text {
            padding: 5px;
            width: 70%;

            .title {
                font-size: 16px;
                font-weight: 600;
                color: $black;

                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            p {
                color: $black;

                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }
        }

        &:hover {
            .image {
                img {
                    transform: scale(1.05);
                }
            }
        }
    }
}
