.content_page_area {
  margin-top: 70px;

  .image {
    border-radius: 15px;
    overflow: hidden;
    float: left;
    padding-right: 40px;
    margin-bottom: 20px;
    @media (max-width:575.98px) {
       padding-right: 0;
    }
    img {
      border-radius: 15px;
    }
  }

  .title {
    font-size: 40px;

    @media (max-width:575.98px) {
      font-size: 30px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    margin-bottom: 30px;
  }

  .content {


    p {
      margin-bottom: 24px;
      text-align: justify;
      font-size: 16px;
    }

    ul {
      padding-left: 30px;
      margin-bottom: 30px;
      list-style-type: disc;

      li {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
      }
    }
  }

  .clr {
    clear: both;
  }
}