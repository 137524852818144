.fxt-template-layout10 .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-layout10 .fxt-bg-color {
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 60px 30px;
  padding-bottom: 0;
}
.fxt-template-layout10 .fxt-bg-img {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 30px 15px;
}
.fxt-template-layout10 .fxt-content {
  max-width: 550px;
  width: 100%;
  border: 1px solid #ddd;
  padding: 40px;
  border-radius: 20px;
}
.fxt-template-layout10 .fxt-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
  text-align: center;
}
.fxt-template-layout10 .fxt-header .fxt-logo {
  display: block;
  margin-bottom: 30px;
  max-width: 40vw;
}
.fxt-template-layout10 .fxt-header h1 {
  color: #fff;
}
.fxt-template-layout10 .fxt-header p {
  color: #fff;
}
.fxt-template-layout10 .fxt-form {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 0;
}
.fxt-template-layout10 .fxt-form h2 {
  font-weight: 600;
  margin-bottom: 40px;
  font-size: 24px;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout10 .fxt-form h2 {
    font-size: 22px;
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout10 .fxt-form h2 {
    font-size: 20px;
    margin-bottom: 40px;
  }
}
.fxt-template-layout10 .fxt-form .fxt-otp-logo {
  margin-bottom: 20px;
  display: block;
}
.fxt-template-layout10 .fxt-form p {
  margin-bottom: 30px;
  font-size: 17px;
}
.fxt-template-layout10 .fxt-form p span {
  display: block;
}
.fxt-template-layout10 .fxt-form .fxt-otp-label {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
}
.fxt-template-layout10 .fxt-form .form-group {
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}
.fxt-template-layout10 .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 8px;
  top: 50%;
  color: #9f9f9f;
  font-size: 14px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.fxt-template-layout10 .fxt-form .form-group .field-icon:before {
  padding: 12px 0 12px 10px;
}
.fxt-template-layout10 .fxt-form .form-control {
  min-height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 30px 10px 0;
  color: #111;
  font-weight: 500;
  border-radius: 0;
  font-size: 15px;
  &::placeholder{
    font-weight: 500;
    font-size: 15px;
    color: #111;
    opacity: 1;
  }
}
.fxt-template-layout10 .fxt-form .form-select {
  min-height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 30px 10px 0;
  color: #111;
  font-weight: 500;
  border-radius: 0;
  font-size: 15px;
  &::placeholder{
    font-weight: 500;
    font-size: 15px;
    color: #111;
    opacity: 1;
  }
  option{
    background: #fff;
    margin-left: 20px;
  }
}
.fxt-template-layout10 .fxt-form input::-webkit-input-placeholder {
  color: #999999;
  font-size: 18px;
  font-weight: 300;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout10 .fxt-form input::-webkit-input-placeholder {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout10 .fxt-form input::-webkit-input-placeholder {
    font-size: 16px;
  }
}
.fxt-template-layout10 .fxt-form input::-moz-placeholder {
  color: #999999;
  font-size: 18px;
  font-weight: 300;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout10 .fxt-form input::-moz-placeholder {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout10 .fxt-form input::-moz-placeholder {
    font-size: 16px;
  }
}
.fxt-template-layout10 .fxt-form input:-moz-placeholder {
  color: #999999;
  font-size: 18px;
  font-weight: 300;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout10 .fxt-form input:-moz-placeholder {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout10 .fxt-form input:-moz-placeholder {
    font-size: 16px;
  }
}
.fxt-template-layout10 .fxt-form input:-ms-input-placeholder {
  color: #999999;
  font-size: 18px;
  font-weight: 300;
}
@media only screen and (max-width: 991px) {
  .fxt-template-layout10 .fxt-form input:-ms-input-placeholder {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-layout10 .fxt-form input:-ms-input-placeholder {
    font-size: 16px;
  }
}
.fxt-template-layout10 .fxt-form .fxt-form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.fxt-template-layout10 .fxt-form .fxt-form-row .fxt-form-col {
  padding: 10px 5px;
  text-align: center;
  margin-right: 10px;
  flex-basis: 0;
  flex-grow: 1;
}
.fxt-template-layout10 .fxt-form .fxt-form-row .fxt-form-col:last-child {
  margin-right: 0;
}
.fxt-template-layout10 .fxt-form .fxt-form-btn {
  margin-bottom: 10px;
}
.fxt-template-layout10 .fxt-form .fxt-resend-wrap {
  margin-bottom: 10px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 18px;
  width: 100%;
}
.fxt-template-layout10 .fxt-form .fxt-btn-resend {
  margin-left: 3px;
  box-shadow: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  color: #63bbff;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout10 .fxt-form .fxt-btn-resend:focus {
  outline: none;
}
.fxt-template-layout10 .fxt-form .fxt-btn-resend:hover {
  color: #000000;
}
.fxt-template-layout10 .fxt-form .text-or {
  margin-left: 3px;
  color: #000000;
}
.fxt-template-layout10 .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: $primary;
  padding: 10px 36px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout10 .fxt-btn-fill:hover {
  background-color: $black;
}
.fxt-template-layout10 .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-layout10 .fxt-btn-ghost {
  margin-top: 15px;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 3px;
  background-color: transparent;
  padding: 10px 36px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout10 .fxt-btn-ghost:hover {
  background-color: #fff;
  border-color: #fff;
  color: $primary;
}
.fxt-template-layout10 .fxt-btn-ghost:focus {
  outline: none;
}
.fxt-template-layout10 .switcher-text2 {
  color: $black;
  font-size: 15px;
  margin-top: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover{
    color: $primary;
  }
}
.fxt-template-layout10 .switcher-text2:last-child {
  margin-right: 0;
}

.fxt-template-layout10 .switcher-text2.active {
  color: #666;
}
.fxt-template-layout10 .checkbox {
  padding-left: 5px;
  margin-right: 30px;
}
.fxt-template-layout10 .checkbox label {
  padding-left: 20px;
  color: #9f9f9f;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-layout10 .checkbox label:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 2px;
  background-color: transparent;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-layout10 .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-layout10 .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-layout10 .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-layout10 .checkbox input[type="checkbox"]:checked + label::before {
  background-color: $primary;
  border-color: $primary;
}


.breadcrumb_ul{
  li a{
    color: #A66618;
  }
}