.new_publication_list {
    .new_pub_item {
        position: relative;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px;
        overflow: hidden;

        .img {
            overflow: hidden;
            height: 437px;
            @media (max-width:1399.98px) {
              height: 374px;
            }
            @media (max-width:1199.98px) {
              height: 303px;
            }
            @media (max-width:991.98px) {
              height: 481px;
            }
            @media (max-width:767.98px) {
              height: unset;
            }

            img{
              height: 100%;
              object-fit: cover;
            }

            img {
                transition: $transition;
            }
        }

        .text {
            background: $white;
            padding: 20px 14px;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: scaleX(0);
            transform-origin: bottom left;
            transition: $transition;
            opacity: 0;
            width: 100%;

            .title {
                font-size: 16px;
                font-weight: 600;
                color: $black;
            }
        }

        &:hover {
            .text {
                transform: scaleX(1);
                opacity: 1;
            }

            .img {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}


.member-dashboard {
  .pub-title{
    background: #282B66;
    color: #ffffff;
    font-family: "Roboto Condensed", serif;
    font-size: 23px;
    font-weight: 600;
    padding: 5px 8px;
    margin-bottom: 10px;
  }
}