.contact_section {
    margin-top: 80px;

    .contact_info {
        margin-bottom: 60px;

        .info_item {
            padding: 40px 25px 45px;
            background: #f8f8f8;
            text-align: center;
            transition: all 0.4s ease;
            height: 100%;
            transition: $transition;
            clip-path: polygon(0% 10%, 10% 10%, 10% 0%, 90% 0%, 90% 10%, 100% 10%, 100% 90%, 90% 90%, 90% 100%, 10% 100%, 10% 90%, 0% 90%);

            .icon_box {
                margin-bottom: 20px;

                img {
                    width: 50px;
                    transition: $transition;
                }
            }

            .title_box {
                margin-bottom: 20px;

                .title {
                    color: $black;
                    font-size: 24px;
                    transition: $transition;
                }
            }

            p,a {
                transition: $transition;
            }

            .content_box {
                .text {
                    font-size: 16px;
                    font-weight: 400;
                }
            }

            &:hover {
                border-color: transparent;
                background: $black;

                .title,
                p {
                    color: $white;
                }

                .icon_box {

                    img {
                        filter: brightness(0) invert(1);
                    }
                }

            }
        }
    }

}

.contact_form {
    .form {
        padding: 40px;
        border: 1px solid rgba(0, 0, 0, 0.10);

        @media (max-width:767.98px) {
            padding: 24px;
        }

        .title {
            color: $black;
            font-size: 34px;
            font-weight: 400;
            margin-bottom: 10px;
            
            text-transform: uppercase;

            @media (max-width:575.98px) {
                font-size: 28px;
            }

            span {
                font-weight: 600;
                color: $primary;
            }
        }

        p {
            font-size: 15px;
            text-transform: uppercase;
            font-weight: 500;
        }

        .form_main {
            margin-top: 30px;

            .input_group {
                label {
                    font-size: 13px;
                    font-weight: 500;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                }

                input,
                select,
                textarea {
                    width: 100%;
                    border: 1px solid rgba(0, 0, 0, 0.10);
                    padding: 0 15px;
                    height: 50px;
                    font-size: 15px;
                    border-radius: 0;
                    transition: $transition;

                    &:focus {
                        border-color: $black;
                    }
                }

                textarea {
                    height: 130px;
                    padding: 15px;
                }
            }

            .submit_btm {
                background: $primary;
                color: $white;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                border: 0;
                padding: 16px 10px;

                &:hover {
                    background: $black;
                }
            }
        }
    }

    .map {
        height: 100%;

        @media (max-width:1199.98px) {
            height: 500px;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}
