.new_gallery_area {
    .photo-album {
        .new_photo_item {
            border: 1px solid transparent;
            transition: $transition;
            overflow: hidden;
            border-radius: 10px;

            .card-img-overlay {
                display: inline-flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 10px;
                transform: translateY(20px);
                transition: $transition;
                visibility: hidden;
                opacity: 0;
            }

            .text {
                background: $white;
            }

            .p-number {
                background: $primary;
                border-radius: 5px;
                font-size: 15px;
                font-weight: 500;
                padding: 3px 8px;
                display: inline-block;
                color: $white;
                margin-bottom: 5px;
                width: 60px;
                text-align: center;
            }

            .p-title {
                font-size: 12px;
                font-weight: 600;
                color: $black;
            }

            &:hover {
                border-color: $black;

                .card-img-overlay {
                    transform: translateY(0);
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}

.gallery .gallery-main .image {
    border-radius: 10px;
    overflow: hidden;
}

.gallery .gallery-main .image .overlay i {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    line-height: 50px;
}

.gallery .gallery-main .image iframe {
    height: 100% !important;
}

.faculty-profile-details .img {
    height: unset !important;
    top: 150px !important;
}

.faculty-profile-details .person-text h2 {
    font-size: 16px;
    margin-bottom: 10px;
}
