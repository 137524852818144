.faq_page_area {
  padding-top: 80px;

  .faq-body {
    .accordion-button:focus {
      box-shadow: none;
    }

    .accordion-item {
      border: 1px solid #ddd;
      border-radius: 8px !important;
    }

    .accordion-button {
      color: $black;
      font-size: 24px;
      font-weight: 500;
      line-height: 110%;
      background: #f5f5f5;
      border: 1px solid transparent;
      border-radius: 8px !important;
      padding: 24px;
      position: relative;

      @media (max-width:575.98px) {
        font-size: 20px;
        padding: 16px;
      }

      &::after {
        background: none;
      }

      .arrow-icon {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        background: #f5f5f5;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 20px;
          height: 20px;
          stroke: $primary;
        }

        .minus {
          display: none;
        }
      }
    }

    .accordion-button:not(.collapsed) {
      background: transparent;
      box-shadow: none;
      color: $black;

      .arrow-icon {
        background: #f5f5f5;

        .minus {
          display: block;
        }

        .plus {
          display: none;
        }
      }
    }

    .accordion-item+.accordion-item {
      margin-top: 16px;
    }

    .accordion-body {
      padding: 24px;
      padding-top: 12px;
      @media (max-width:575.98px) {
        padding: 16px;
        padding-top: 0;
      }
    }
  }
}