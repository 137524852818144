.blog-item {
    background-color: $white;
    box-shadow: 0px 5px 60px 0px rgba(0, 0, 0, 0.05);
    height: 100%;

    .img {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        height: 277px;
        overflow: hidden;

        @media (max-width:1399.98px) {
            height: 237px;
        }

        @media (max-width:1199.98px) {
            height: 197px;
        }

        @media (max-width:991.98px) {
            height: 224px;
        }

        @media (max-width:767.98px) {
            height: 334px;
        }

        @media (max-width:575.98px) {
            height: unset;
        }

        a {
            height: 100%;
        }

        img {
            transition: $transition;
            height: 100%;
            object-fit: cover;
        }
    }

    .text {
        padding: 20px 30px 40px 30px;

        .blog-title {
            margin-bottom: 14px;
            height: unset;
            font-size: 20px;
            font-weight: 600;
            margin-top: 5px;

            a {
                color: $black;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            &:hover {
                a {
                    color: $black;
                }
            }
        }

        p {
            margin-bottom: 30px;
        }
    }

    &:hover {
        .img {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.blog-meta {

    span {
        color: $black;
        font-size: 15px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.36px;
        opacity: 0.8;

        i {
            color: $primary;
            font-size: 14px;
            margin-right: 10px;
        }
    }
}

.blog-sidebar {
    position: sticky;
    top: 160px;
    @media (max-width:1199.98px) {
        margin-top: 40px;
    }
    .blog-widget {
        border: 1px solid #CCCCCC;
        padding: 30px;
        border-radius: 10px;

        .section-sub-title {
            font-weight: 700;
            font-size: 22px;
            line-height: 36px;
            letter-spacing: 0;
            padding-right: 20px;
            color: $black;
        }

        .input-group {
            .form-control {
                width: 100%;
                height: 58px;
                border-radius: 50px !important;
                background-color: rgba(255, 255, 255, 0.1);
                padding: 0 20px;
                border: 0;
                color: $black;
                padding-right: 73px;
                background-color: #F1F6FC;
                border: 1px solid transparent;

                &:focus {
                    z-index: 1;
                    border-color: $primary;
                    color: $black;
                }

                &::placeholder {
                    color: #49515B;
                }
            }

            button {
                width: 58px;
                height: 58px;
                background-color: $primary;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                color: $white;
                border: 0;

                &:hover {
                    background: $black;
                }
            }
        }

        .recent-blog {
            .blog-sm-item {
                gap: 15px;

                .image {
                    border-radius: 20px 0 0 20px;
                    width: 120px;
                    height: 80px;
                    flex: 0 0 auto;

                    @media (max-width:1399.98px) {
                        width: 110px;
                        height: 73px;
                    }

                    a {
                        height: 100%;
                    }

                    img {
                        transition: $transition;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .text {
                    .blog-meta {
                        gap: 10px;
                        row-gap: 5px;
                        margin-bottom: 5px;

                        li {
                            font-size: 14px;
                            font-weight: 400;

                            a {
                                color: #49515B;

                                &:hover {
                                    color: $primary;
                                }
                            }
                        }
                    }

                    .blog-title {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 600;

                        a {
                            color: $black;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;

                            &:hover {
                                color: $primary;
                            }
                        }
                    }
                }

                &:hover {
                    .image {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }

            .blog-sm-item+.blog-sm-item {
                margin-top: 20px;
            }
        }
    }

    .blog-widget+.blog-widget {
        margin-top: 30px;
    }
}

.section-title-block {
    margin-bottom: 20px;

    .section-sub-title {
        font-weight: 700;
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0;
        padding-right: 20px;
        color: $black;

        &::before {
            position: absolute;
            left: 100%;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            background-color: $black;
            content: "";
            width: 30px;
            height: 2px;
        }

        &::after {
            position: absolute;
            right: -40px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            background-color: $black;
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }
    }
}

.new_research_item {
    padding: 15px;
    padding-bottom: 30px;
    background: $white;
    border-radius: 10px;
    filter: drop-shadow(0px 14px 19px rgba(221, 229, 236, 0.42));

    .img {
        margin-bottom: 20px;
        overflow: hidden;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        height: 286px;

        @media (max-width:1399.98px) {
            height: 242px;
        }
        @media (max-width:1199.98px) {
            height: 196px;
        }
        @media (max-width:991.98px) {
            height: 227px;
        }
        @media (max-width:767.98px) {
            height: 306px;
        }
        @media (max-width:575.98px) {
            height: unset;
        }

        img {
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            transition: $transition;
            height: 100%;
            object-fit: cover;
        }
    }

    .text {
        .rs_title {
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: 600;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    &:hover {
        .img {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.rounded-20 {
    border-radius: 20px;
}

// news details css
.new_news_details {
    .blog-details-banner {
        border-radius: 20px 20px 0 0;

        img {
            border-radius: 20px 20px 0 0;
        }

        .blog-meta {
            background-color: $primary;
            padding: 8px 20px;
            top: 40px;
            left: 40px;
            border-radius: 20px;

            a {
                color: $white;
                gap: 8px;

                i {
                    color: $white;
                }
            }
        }
    }

    .blog-description {
        padding: 22px 40px 40px;
        border: 2px solid #ccc;
        border-top: 0;
        border-radius: 0px 0px 20px 20px;

        .title {
            font-size: 28px;
            font-weight: 600;
            margin-bottom: 26px;
        }

        p+p {
            margin-top: 20px;
        }

        .list-info {
            margin: 20px 0 40px 0;

            li {

                i {
                    color: $primary;
                    margin-right: 15px;
                }
            }

            li+li {
                margin-top: 15px;
            }
        }

        blockquote {
            padding: 20px 30px 30px;
            margin-bottom: 60px;

            .name {
                padding-left: 40px;

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 30px;
                    height: 2px;
                    background-color: $primary;
                    content: "";
                }
            }
        }
    }
}

.page_header {
    ul {
        justify-content: flex-start !important;
    }
}

@media (max-width:1399.98px) {
    .new_news_details .blog-description .tags-and-social-icon .tags ul li {
        font-size: 16px;
    }
}

@media (max-width:767.98px) {
    .new_news_details .blog-description {
        padding: 22px 24px 30px;
    }

    .new_news_details .blog-description .tags-and-social-icon .tags ul li a {
        font-size: 16px;
    }

    .new_news_details .blog-details-pagination ul li a .icon {
        width: 45px;
        height: 45px;
    }
}

@media (max-width:575.98px) {
    .new_news_details .blog-details-banner .blog-meta {
        position: static !important;
        margin-top: 20px;
        width: 100%;
        flex-wrap: wrap;
        row-gap: 5px !important;
    }

    .new_news_details .blog-description {
        border-top: 2px solid #ccc;
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
    }

    .new_news_details .blog-description .title {
        font-size: 28px;
    }

    .new_news_details .blog-description .list-info .list-title {
        font-size: 17px;
        line-height: 24px;
        font-weight: 700;
    }

    .new_news_details .blog-details-pagination {
        padding: 20px;
        margin-top: 60px;
    }

    .new_news_details .blog-details-pagination ul::before {
        display: none;
    }
}