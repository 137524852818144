@import "../variable/variable";
@import "../mixin/font";

.social_icon {
    li {
        a {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-size: 20px;
            border-radius: 4px;
            &:hover {
                transform: translateY(-5px);
            }
        }

        .fb {
            background: #3b5998;
        }

        .tw {
            background: #000000;
        }

        .lin {
            background: #006599;
        }

        .yt {
            background: #bd0f33;
        }
    }
}

.footer_area {
    margin-top: 80px;
    background: #002147;
    padding: 30px 0 20px;

    .footer_social {
        margin-bottom: 60px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding-bottom: 30px;

        .title {
            font-family: $outfit;
            font-size: 20px;
            font-weight: 400;
        }
    }

    .footer_widget {
        .ft_title {
            color: $white;
            font-size: 20px;
            font-weight: 500;
            
            margin-bottom: 10px;
        }

        .w_title {
            color: $white;
            font-size: 16px;
            font-weight: 500;
            
            margin-bottom: 0px;
        }

        ul {
            li {
                a {
                    font-size: 12px;
                    font-weight: 500;
                    
                    color: $white;
                }
            }
        }

        .widget_item + .widget_item {
            margin-top: 20px;
        }
        .maps {
            iframe {
                width: 100% !important;
                height: 191px !important;
            }
        }
    }

    .visitor {
        padding: 16px 0;
        border: 2px solid $primary;
        border-radius: 3px;
        background-color: $white;
        p {
            color: $black;
            font-size: 15px;
        }
    }

    .contact_widget {
        .info {
            font-size: 12px;
            font-weight: 500;
            
            color: $white;
        }

        ul {
            li {
                display: flex;
                gap: 10px;

                .icon {
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    background: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        padding: 2px;
                    }
                }
            }

            li + li {
                margin-top: 5px;
            }
        }
    }

    .footer_btm {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        margin-top: 60px;
        padding-top: 20px;

        p {
            font-size: 12px;
            font-weight: 500;
            
            color: $white;
        }
    }
}

.new_subscribe_form {
    position: relative;
    input {
        height: 50px;
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 5px;
        color: $white;
        padding: 0 20px;
        transition: $transition;
        &:focus {
            border-color: $white;
        }
    }
    button {
        background: $primary;
        border-radius: 5px;
        color: $white;
        width: 42px;
        height: 42px;
        text-align: center;
        line-height: 42px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 4px;
        border: 0;
        &:hover {
            background: $black;
        }
    }
}

/* scroll to top area */
.scroll-to-top {
    display: inline-block;
    background: $primary;
    width: 40px;
    height: 40px;
    line-height: 45px;
    color: $white;
    text-align: center;
    border-radius: 5px;
    border: 0;
    position: fixed;
    bottom: 0px;
    right: 15px;
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    opacity: 0;
    visibility: hidden;
    font-size: 18px;
    z-index: 99;
    cursor: pointer;
    transition: $transition;

    &:hover {
        background: $black;
        color: $white;
    }

    span {
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
    }
}

.scroll-to-top.show {
    opacity: 1;
    visibility: visible;
    bottom: 20px;
}

#gb-widget-9064 {
    bottom: 75px !important;
    right: 5px !important;
}
.sc-q8c6tt-3.hKYcqG{
  margin-top: -200px;
  margin-right: -5px;
}

.sc-sbsi7l-0.cLcbjv{
    display: none !important;
}
